.date-picker-control{
  font-family: var(--font-family-primary);
  &__input{
    display: flex;
    height: 36px;
    padding: 0 30px 0 8px;
    font-size: 14px;
    width: 100%;
    background: transparent;
    outline: none;
    border: none;
    border-bottom: 1px solid #2b2b2b;
    transition: all 300ms ease;
    color: white;
    &:hover, &:focus{
      border-bottom: 1px solid #006280;
      outline: none;
    }
  }
  &__icon{
    width: 20px;
    cursor: pointer;
    position: absolute;
    right: 2px;
    top: 4px;
  }
  &__calendar{
    position: absolute;
    background: white;
    z-index: 1;
    margin-top: 8px;
    box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.15);
    padding: 4px;
    border-radius: 0px;
    visibility: hidden;
    &.visible{
      visibility: visible;
    }
  }
}


.react-calendar {
  font-family: var(--font-family-primary);
  border: none;
  .react-calendar__tile--active,
  .react-calendar__tile--active:enabled:hover,
  .react-calendar__tile--active:enabled:active,
  .react-calendar__tile--active:enabled:focus {
    background: #006280;
    outline: none;
  }
  .react-calendar__tile.react-calendar__month-view__days__day {
    outline: none;
  }
  .react-calendar__navigation button:enabled:hover,
  .react-calendar__navigation button:enabled:focus {
    outline: none;
  }
  .react-calendar__month-view__weekdays__weekday{
    abbr{
      text-decoration: none;
    }
  }
  .react-calendar__tile:enabled:hover, .react-calendar__tile:enabled:focus,
  .react-calendar__navigation button:enabled:hover, .react-calendar__navigation button:enabled:focus 
  {
    background-color: #00628080;
  }
  .react-calendar__navigation{
    margin-bottom: 8px;
    border-bottom: 1px solid var(--grey);
  }
  .react-calendar__tile--now{
    background: var(--yellow);
  }
}
