.select-container{
  position:relative;
  display:flex;
  padding: 0;
  outline:0;
  text-align:left;
  width: fit-content;
  height: 32px;
  .menu-button{
    line-height: 32px;
    border-radius: 0px;
    background-color: #191919;
    border: 1px solid #2b2b2b;
    color: white;
    display: flex;
    cursor: pointer;
    width: 100%;
    height: 32px;
    line-height: 32px;
    margin: auto 0;
    padding: 0px 8px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width:fit-content;
    font-size: 12px;
    transition: all 300ms ease;
    svg{
      margin: auto 0 auto auto;
      width: 12px;
    }
    &__drop-down{
      width: 8px;
      transition: all 300ms ease;
      margin: auto 0 auto 8px;
    }
    &:hover{
      background: #555454;
    }
  }

  &:focus .menu-button { pointer-events:none; border-color: #fff; }

  /* If :focus detected the dropdown menu is displayed by making it visible */
  &:focus .menu-dropdown { opacity:1; visibility:visible; }
  &:focus .menu-button__drop-down { 
    transform:rotate(180deg)
   }

  /* style for the dropdown box */
  .menu-dropdown {
    width: 100%;
    margin: 3px 0 0 0;
    padding: 8px 0;
    pointer-events: auto;
    position: absolute;
    z-index: 2;
    opacity: 0;
    left: 0px;
    top: 32px;
    visibility: hidden;
    // transition: all 300ms ease;
    color: white;
    background: #191919;
    box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.15);
    border-radius: 0px;
    max-height: 320px;
    overflow: auto;
    .hidden{
      visibility: hidden;
    }
   }

  /* style the links in the dropdown */
  .menu-dropdown > .menu-item {
    border:none;
    outline:0;
    display:block;
    color: white;
    background: #191919;
    text-decoration:none;
    width:180px;
    padding: 0 12px;
    height: 32px;
    line-height: 32px;
    cursor: pointer;
    font-size: 12px;
    position: relative;
    transition: all 300ms ease;
    &:hover{
      background: #555454;
      border-radius: 0px;
      color: white;
    }
    &.active::after{
      content: ' ';
      position: absolute;
      width: 12px;
      height: 6px;
      border-left: 2px solid #3bc7e3;
      border-bottom: 2px solid #3bc7e3;
      transform: rotate(-45deg);
      top: 11px;
      transition: all 300ms ease;
      right: 12px;
    }
  }
}

.select-label{
  margin-right: 12px;
  font-size: 14px;
  height: 32px;
  line-height: 32px;
  color: #8B90A0;
}