.search-container {
  background-color: #141414;
  height: 48px;
  border-bottom: 4px solid #0a0a0a;
  padding: 0 10px;
}

.search-button {
  color: white;
  font-size:20px;
  cursor:pointer;
  margin:12px 20px 12px 5px;
  &:hover {
    color: #555454;
  }
}


.search-circular{

  position:relative;
  display:flex;
  padding: 0;
  outline:0;
  text-align:left;
  width: 100%;
  height: 36px;
  box-sizing: border-box;
  background-color: #141414;
  padding: 4px 12px;

  .menu-button{

    line-height: 32px;
    border-radius: 0px;
    background-color: transparent;
    border-bottom: 1px solid #2b2b2b;
    color: white;
    display: flex;
    cursor: pointer;
    width: 100%;
    height: 32px;
    line-height: 32px;
    margin: auto 0;
    padding: 0px 0px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    font-size: 12px;
    transition: all 300ms ease;

    svg{
      margin: auto 0 auto auto;
      width: 12px;
      margin-right: 8px;
    }

    &__drop-down{
      width: 8px;
      transition: all 300ms ease;
      margin: auto 0 auto 8px;
    }
    // &:hover{
    //   background: #555454;
    // }
  }

  &:focus .menu-button { pointer-events:none; border-color: #fff; }

  /* If :focus detected the dropdown menu is displayed by making it visible */
  &:focus .menu-dropdown { opacity:1; visibility:visible; }
  &:focus .menu-button__drop-down { 
    transform:rotate(180deg)
   }

  /* style for the dropdown box */
  .menu-dropdown {
    width: 100%;
    margin: 3px 0 0 0;
    padding: 8px 0;
    pointer-events: auto;
    position: absolute;
    z-index: 1;
    left: 0px;
    top: 32px;
    // transition: all 300ms ease;
    color: white;
    background: #191919;
    box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.15);
    border-radius: 0px;
    max-height: 320px;
    overflow: auto;
    .hidden{
      visibility: hidden;
    }
    flex: 1;
    margin-right: 4px;
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 14px;
      background: var(--backgrounds-color);
      cursor: pointer;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: var(--backgrounds-color);
      border: 1px solid var(--items-hover-color);
      cursor: pointer;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: var(--items-hover-color);
      cursor: pointer;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #555;
      cursor: pointer;
    }
   }

  /* style the links in the dropdown */
  .menu-dropdown > .menu-item {
    border:none;
    outline:0;
    display:block;
    color: white;
    background: #191919;
    text-decoration:none;
    padding: 0 12px;
    height: 32px;
    line-height: 32px;
    cursor: pointer;
    font-size: 12px;
    position: relative;
    transition: all 300ms ease;
    &:hover{
      background: #555454;
      border-radius: 0px;
      color: white;
    }
    &.active::after{
      content: ' ';
      position: absolute;
      width: 12px;
      height: 6px;
      border-left: 2px solid #3bc7e3;
      border-bottom: 2px solid #3bc7e3;
      transform: rotate(-45deg);
      top: 11px;
      transition: all 300ms ease;
      right: 12px;
    }
  }
}
